<template>
  <view-card-template :loaded="loaded">
    <template #cardTitle>
      {{ ballot ? `Awards for ${ballot.title}` : `Loading...` }}
    </template>

    <template #cardBody>
      <div
        class="d-flex flex-column"
        style="height: 100%"
      >
        <v-container>
          <!-- Nomination Page Description -->
          <div
            v-if="ballot && ballot.nominationsPageDescription && ballot.nominationsPageDescription.length"
            class="d-flex flex-column my-2"
          >
            <h4 class="mb-2">Description</h4>
            <div v-html="ballot.nominationsPageDescription"></div>
          </div>

          <!-- START Award Expansion Panels -->
          <v-expansion-panels
            v-if="ballot && ballot.awards.length"
            multiple
            focusable
          >
            <v-expansion-panel
              v-for="(award, index) in ballot.awards"
              :key="index"
            >
              <v-expansion-panel-header>
                <div class="d-flex justify-space-between">                
                  <h4>{{ award.awardType.title }}</h4>
                  <h4 class="px-4">
                    ({{ award.numOpenSlots }}
                    Nomination{{ award.numOpenSlots == 1 ? '' : 's' }}
                    Remaining)
                  </h4>
                </div>
              </v-expansion-panel-header>
              
              <v-expansion-panel-content class="panel-with-no-padding">
                <v-data-table
                  :headers="headers"
                  :items="award.nominations"
                  :items-per-page="-1"
                  hide-default-footer
                  dense
                >
                  <template #item="{ item }">
                    <tr>
                      <td class="font-weight-medium">{{ item.fullName }}</td>
                      <td class="text-center">
                        <v-btn
                          v-if="item.id == null"
                          @click="nominateForAward(award)"
                          :to="`/voting/nominate/${ballotId}/${award.awardType.id}`"
                          class="my-1 white--text mx-2"
                          color="amber darken-2"
                          large
                          
                        >
                          Nominate
                        </v-btn>
                        <v-btn
                          v-else
                          @click="editNomination(item, award)"
                          :to="`/voting/edit-nomination/${ballotId}/${award.awardType.id}/${item.id}`"
                          class="my-1 white--text"
                          color="blue darken-2 mx-2"
                          large
                        >
                          Edit
                        </v-btn>
                        <v-btn
                          v-if="item.id != null"
                          @click="openDeleteNomination(item, award)"
                          class="my-1 white--text"
                          color="red darken-2"
                          large
                        >
                          Delete
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                
              </v-expansion-panel-content>
              
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- END Award Expansion Panels -->
        </v-container>

        <!-- START Page Actions -->
        <v-card-actions style="margin-top: auto;">
          <v-spacer/>
          <v-btn
            @click="navigateBack"
            depressed
          >
            Back
          </v-btn>
        </v-card-actions>
        <!-- END Page Actions -->
      </div>
      <app-action-modal
        @cancel="deleteModal = false"
        @action="deleteNomination(nominationUpForDeletion)"
        :value="deleteModal"
        :loading="nominationDeleteLoading"
        :header-text="
          `Delete nomination for ${!nominationUpForDeletion?.length > 0 
            ? nominationUpForDeletion != null
              ? `${nominationUpForDeletion.firstName} ${nominationUpForDeletion.lastName}`
              : ''
            : 'those selected'}`
        "
        warning-text="Are you sure you want to delete this nomination? This action cannot be undone."
      />
    </template>
  </view-card-template>
</template>

<script>
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'
import AppActionModal from "@/components/app/AppActionModal.vue"

import Controllers from "@/data/controllers"

export default {
  name: "SelectAward",

  components: {
    ViewCardTemplate,
    AppActionModal
  },

  props:  {
    ballotId: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    loaded: false,
    deleteModal: false,
    nominationUpForDeletion: {},
    nominationDeleteLoading: null,
    ballot: null,
    headers: [
      {
        text: "Nominee",
        align: "start",
        sortable: true,
        value: "fullName",
        width: "75%",
      },
      {
        text: "Action",
        align: "center",
        sortable: false,
      }
    ],
  }),

  computed: {
    //
  },

  methods: {
    navigateBack() {
      this.$router.push({ name: "Voting" })
    },

    nominateForAward(award) {
      this.$router.push({
        name: "Add Nomination",
        params: {
          ballotId: this.ballotId,
          awardTypeId: award.awardType.id,
        }
      })
    },

    editNomination(nomination, award) {
      this.$router.push({
        name: "Edit Nomination",
        params: {
          ballotId: this.ballotId,
          awardTypeId: award.awardType.id,
          nominationId: nomination.id,
        }
      })
    },
    openDeleteNomination(nomination) {
      this.nominationUpForDeletion = nomination
      this.deleteModal = true
    },
    async deleteNomination(nomination) {
      this.nominationDeleteLoading = true
      const res = await Controllers.NominationController.DeleteNomination(nomination.id)
      if(res && !res.hasError) this.$root.showSnackbar('Nomination deleted successfully!', "success", 5000)
      else this.$root.showSnackbar('There was an error deleting the nomination.', "error", 5000)

      this.getBallot();      

      this.nominationDeleteLoading = false
      this.deleteModal = false
      this.nominationUpForDeletion = null
    },
    async getBallot() {
      const ballotRes = await Controllers.BallotController.GetBallot(this.ballotId)
      
      if(ballotRes && !ballotRes.hasError) {
        const ballot = ballotRes.data

      // Make nomination slots
      
        // Alphabetize awards
        ballot.awards = ballot.awards.sort((a, b) => a.awardType.title.localeCompare(b.awardType.title))

        // Make nomination slots
        ballot.awards.forEach(award => {
          award.nominations.forEach(nomination => {
            const fName = nomination.firstName.split('/')
            const lName = nomination.lastName.split('/')
            nomination.fullName = fName.length > 1 || lName.length > 1 ?
            `${fName[0]} ${lName[0]} / ${fName[1]} ${lName[1]}` :
            `${nomination.firstName} ${nomination.lastName}`
            return nomination
          })
          award.numOpenSlots = award.numNominationsAllowed - award.nominations.length
          for(let i=0; i < award.numOpenSlots; ++i) {
            award.nominations.push({
              fullName: "-"
            })
          }
        })

        this.ballot = ballot
      }
      else {
        // Error handling
        this.$root.showSnackbar('Ballot not found or wrong conference selected.', "error", 5000)
      }
    }
  },
  async created() {
    this.$root.updateTitle("Select Award")
    
    await this.getBallot();

    this.loaded = true
  },
}
</script>

<style lang="scss">
.panel-with-no-padding .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>